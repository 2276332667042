/* ProductGrid.css */

/* Basic grid setup */
.product-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    padding: 20px;
    margin-top: 20px;
  }
  
  /* Product card styling */
  .product-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    overflow: hidden;
  }
  
  /* Hover effect for product cards */
  .product-card:hover {
    transform: translateY(-5px);
  }
  
  /* Image styling */
  .product-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-bottom: 1px solid #ddd;
  }
  
  /* Product info */
  .product-info {
    padding: 15px;
  }
  
  .product-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
  }
  
  .product-description {
    font-size: 1rem;
    color: #666;
    margin-bottom: 15px;
  }
  
  .product-price {
    font-size: 1.25rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  /* Add to cart button styling */
  .add-to-cart-button {
    background-color: #007bff;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  /* Hover effect for add-to-cart button */
  .add-to-cart-button:hover {
    background-color: #0056b3;
  }
  
  @media (max-width: 768px) {
    .product-grid {
      grid-template-columns: 1fr 1fr;
    }
  }
  
  @media (max-width: 480px) {
    .product-grid {
      grid-template-columns: 1fr;
    }
  }
  