/* Navbar.css */

.navbar {
    background-color: #333;
    padding: 15px 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .navbar ul {
    list-style: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0;
    padding: 0;
  }
  
  .navbar li {
    margin: 0 15px;
  }
  
  .navbar button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .navbar button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
  }
  
  .navbar button:focus {
    outline: none;
  }
  
  @media (max-width: 768px) {
    .navbar ul {
      flex-direction: column;
      align-items: flex-start;
      padding-left: 20px;
    }
  
    .navbar li {
      margin: 10px 0;
    }
  }
  