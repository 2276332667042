/* src/styles/Footer.css */
footer {
    background-color: #333;
    color: white;
    padding: 20px;
    text-align: center;
  }
  
  footer a {
    color: #ddd;
    text-decoration: none;
  }
  
  footer a:hover {
    text-decoration: underline;
  }
  